<!-- 代理商提单 -->
<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <el-form
          :model="searchModel"
          :rules="rules"
          ref="searchForm"
          :inline="true"
        >
          <el-form-item label="提单号" prop="cnumber">
            <el-input
              placeholder="输入提单号模糊查询"
              v-model="searchModel.cnumber"
              clearable
              @keyup.enter.native="handleSearch"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="提单时间" prop="flightTime">
            <el-date-picker
              v-model="searchModel.flightTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否有出口许可" prop="hasLicence">
            <el-checkbox-group v-model="searchModel.hasLicence">
              <el-checkbox
                v-for="s in hasLicence"
                :label="s.key"
                :key="s.key"
                >{{ s.value }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="申告番号" prop="reportcode">
            <el-input
              placeholder="输入申告番号模糊查询"
              v-model="searchModel.reportcode"
              clearable
              @keyup.enter.native="handleSearch"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="申告日期" prop="reportdate">
            <el-date-picker
              v-model="searchModel.reportdate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="申告金额" prop="reportpriceMin">
            <el-input
              placeholder="输入申告金额"
              v-model="searchModel.reportpriceMin"
              clearable
              @keyup.enter.native="handleSearch"
              style="width: 190px"
              ><template slot="append">円</template>
            </el-input>
          </el-form-item>
          <el-form-item label="至" prop="reportpriceMax">
            <el-input
              placeholder="输入申告金额"
              v-model="searchModel.reportpriceMax"
              clearable
              @keyup.enter.native="handleSearch"
              style="width: 190px"
              ><template slot="append">円</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="handleSearch"
              icon="el-icon-search"
              type="primary"
              >查询</el-button
            >
            <el-button @click="handleReset" icon="el-icon-refresh-left"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <div class="note">*请至输出免税不适应中给客户开具退税资料!</div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column prop="flighttime" label="提单日期" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.flighttime.slice(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提单号" width="120">
            <template slot-scope="scope">
              <span
                >****<span class="red bold">{{
                  scope.row.cnumber.slice(-4)
                }}</span></span
              >
            </template>
          </el-table-column>
          <el-table-column prop="reportcode" label="申告番号" width="140">
          </el-table-column>
          <el-table-column prop="reportprice" label="申告金额" width="150">
            <template slot-scope="scope">
              {{ scope.row.reportprice | numFormat }}
            </template>
          </el-table-column>
          <el-table-column label="申告日期" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.reportdate | dateFormat('YYYY-MM-DD') }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="出口许可">
            <template slot-scope="scope">
              <template v-if="scope.row.licenceattr">
                <div class="attr-item">
                  <div
                    class="attr-info"
                    v-for="(attr, index) in JSON.parse(scope.row.licenceattr)"
                    :key="index"
                  >
                    <a :href="attr.url" target="_blank">
                      <i class="icon iconfont icon-PDF">{{ attr.name }}</i>
                    </a>
                  </div>
                  <div
                    class="attr-info"
                    v-if="JSON.parse(scope.row.licenceattr).length > 0"
                  >
                    <i
                      class="el-icon-download"
                      @click="handleDownLoadAttr(scope.row)"
                      >下载许可.zip</i
                    >
                  </div>
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import { GetToken } from 'utils/auth'
import Customsbill from 'api/customsbill'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      rules: {
        reportpriceMin: vxRule(false, 'Float2'),
        reportpriceMax: vxRule(false, 'Float2')
      },
      hasLicence: [
        { key: 1, value: '是' },
        { key: 0, value: '否' }
      ], // 是否有出口许可
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      tableData: [],
      tableTotal: 0,
      searchModel: {
        cnumber: '', // 提单号
        flightTime: '', // 航班时间
        reportcode: '', // 申报番号
        reportpriceMin: '', // 申报金额
        reportpriceMax: '', // 申报金额
        reportdate: '', // 申报日期
        hasLicence: [] // 是否有出口许可
      },
      agency: this.$route.query.agency
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getLimits()
  },
  destroyed() {},
  methods: {
    // 批量下载
    handleDownLoadAttr(item) {
      let _ids = []
      _ids.push(item.id)
      console.log('ids:', _ids, _ids.join())
      Customsbill.DownFileAgency({
        token: GetToken(),
        id: _ids.join(),
        type: 'licence'
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '下载中...'
          })
          location.href = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getLimits()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchModel = {
        cnumber: '', // 提单号
        flightTime: '', // 航班时间
        reportcode: '', // 申报番号
        reportpriceMin: '', // 申报金额
        reportpriceMax: '', // 申报金额
        reportdate: '', // 申报日期
        hasLicence: [] // 是否有出口许可
      }
      this.tableData = []
      this.getLimits()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getLimits()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getLimits()
    },
    getLimits() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          let _start = '',
            _end = '',
            _repstart = '',
            _repend = ''
          if (this.searchModel.flightTime) {
            _start = this.searchModel.flightTime[0]
            _end = this.searchModel.flightTime[1]
          }
          if (this.searchModel.reportdate) {
            _repstart = this.searchModel.reportdate[0]
            _repend = this.searchModel.reportdate[1]
          }
          let _min = this.searchModel.reportpriceMin
          let _max = this.searchModel.reportpriceMax
          if (
            this.searchModel.reportpriceMin &&
            this.searchModel.reportpriceMax
          ) {
            if (
              +this.searchModel.reportpriceMin >
              +this.searchModel.reportpriceMax
            ) {
              _max = this.searchModel.reportpriceMin
              _min = this.searchModel.reportpriceMax
            }
          }
          Customsbill.getCustomsbillAgencyList({
            token: GetToken(),
            agency: this.agency,
            cnumber: this.searchModel.cnumber,
            start: _start,
            end: _end,
            reportcode: this.searchModel.reportcode,
            reportpriceMin: _min,
            reportpriceMax: _max,
            reportdateStart: _repstart,
            reportdateEnd: _repend,
            hasLicence: this.searchModel.hasLicence.join(','),
            curr: this.currentPage,
            pagesize: this.pageSize
          }).then((res) => {
            this.tableData = res.record
            this.tableTotal = +res.message
            if (res.success) {
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.search-container {
  .el-form {
    margin-top: 15px;
  }
}
.attr-item {
  display: flex;
  .attr-info {
    margin-right: 20px;
    a {
      font-size: 13px;
      text-decoration: none;
    }
    .icon-PDF {
      font-size: 13px;
    }
  }
}
.note {
  color: red;
}
.el-icon-download {
  cursor: pointer;
}
</style>
